<template>
	<!-- 用户管理 -->
	<div class="rootDiv">
		<div class="retrieve">
			<div class="pv-30 flex flex-ac flex-w">
				<div class="mr-30 flex flex-ac">
					<span class="fs-16 flex1 mr-20 w-80">用户名：</span>
					<el-input class="w-250" v-model="retrieveForm.userName" clearable></el-input>
				</div>
				<div class="mr-30 flex flex-ac">
					<span class="fs-16 flex1 mr-20 w-80">手机号：</span>
					<el-input class="w-250" v-model="retrieveForm.phone" clearable></el-input>
				</div>
				<div class="mr-30 flex flex-ac">
					<span class="fs-16 flex1 mr-20 w-80">黑名单：</span>
					<el-select class="w-250" v-model="retrieveForm.isBlacklist" clearable placeholder="请选择">
						<el-option label="正常" :value="0">
						</el-option>
						<el-option label="异常" :value="1">
						</el-option>
					</el-select>
				</div>
				<div class="mr-30 flex flex-ac">
					<span class="fs-16 flex1 mr-20 w-80">人员类型：</span>
					<el-select class="w-250" v-model="retrieveForm.isTest" clearable placeholder="请选择">
						<el-option label="正式" :value="0">
						</el-option>
						<el-option label="测试" :value="1">
						</el-option>
					</el-select>
				</div>
				<div class="mr-30 mt-20 flex flex-ac">
					<span class="fs-16 flex1 mr-20 w-80">用户ID：</span>
					<el-input class="w-250" v-model="retrieveForm.userId" clearable></el-input>
				</div>
				<div class="mr-30 mt-20 flex flex-ac">
					<span class="fs-16 flex1 mr-20 w-80">创建时间：</span>
					<el-date-picker class="w-250" v-model="startEndDate" type="daterange" valueFormat="yyyy-MM-dd"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handPickerChange">
					</el-date-picker>
				</div>
				<div class="mr-30 mt-20 flex flex-ac">
					<span class="fs-16 flex1 mr-20">排序：</span>
					<el-select class="w-150" v-model="retrieveForm.sortType" placeholder="请选择">
						<el-option label="累计消费" :value="1">
						</el-option>
						<el-option label="创建时间" :value="0">
						</el-option>
					</el-select>
				</div>
				<div class="mt-20">
					<el-button type="primary" @click="submitSearch">查询</el-button>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%"
				:border="false" @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:img="scope">
					<img :src="scope.row.userImg" alt="" class="smallImg" v-if="scope.row.userImg">
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
		<!-- 新增弹框 -->
		<el-dialog :title="'新增' + dialogTitle" :visible.sync="dialogFormVisible" top="20vh" width="30%">
			<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="金额" prop="money" v-if="'money' in ruleForm"
					:rules="{required: true, message: '金额不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.money"></el-input>
				</el-form-item>
				<el-form-item label="使用门槛" prop="doorSill" v-if="'doorSill' in ruleForm"
					:rules="{required: true, message: '使用门槛不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.doorSill"></el-input>
				</el-form-item>
				<el-form-item label="类型" prop="couponsType" v-if="'couponsType' in ruleForm"
					:rules="{required: true, message: '请至少选择一个优惠劵类型', trigger: 'change'}">
					<el-select v-model="ruleForm.couponsType" placeholder="请选择">
						<el-option v-for="item in couponsTypes" :key="item.value" :label="item.key" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="有效天数" prop="day" v-if="'day' in ruleForm"
					:rules="{required: true, message: '有效天数不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.day"></el-input>
				</el-form-item>
				<el-form-item label="赠送数量" prop="num" v-if="'num' in ruleForm"
					:rules="{required: true, message: '赠送数量不能为空', trigger: 'blur'}">
					<el-input type="number" v-model="ruleForm.num"></el-input>
				</el-form-item>
				<el-form-item label="描述" prop="couponsName" v-if="'couponsName' in ruleForm">
					<el-input type="text" v-model="ruleForm.couponsName"></el-input>
				</el-form-item>
				<el-form-item label="卡片" prop="cardId" v-if="'cardId' in ruleForm"
					:rules="{required: true, message: '请至少选择一个优惠劵类型', trigger: 'change'}">
					<el-select v-model="ruleForm.cardId" placeholder="请选择">
						<el-option v-for="item in cardList" :key="item.id" :label="item.cardName" :value="item.id">
							<span style="float: left">{{ item.cardName }}</span>
							<img :src="item.cardImg" alt="" class="w-40 h-40" style="float: right">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
			</div>
		</el-dialog>
		<el-dialog :visible.sync='consumeDialog' width="50%">
			<el-tabs v-model="activeName">
				<el-tab-pane label="一番赏消费" name="first">
					<template v-if='dialogTableData.goodConsumeList'>
						<el-table :header-cell-style="{background:'#eef1f6',color:'#606266'}"
							:data="dialogTableData.goodConsumeList" style="width: 100%">
							<el-table-column prop="grade" label="消费等级"></el-table-column>
							<el-table-column prop="gradeConsume" label="消费金额"></el-table-column>
							<el-table-column prop="consume" label="消费"></el-table-column>
							<el-table-column label="操作" width="100">
								<template slot-scope="scope">
									<el-button type="text" class="eidtBtn" size="medium"
										@click="handleClick(scope.row)">编辑</el-button>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-tab-pane>
				<el-tab-pane label="活动消费" name="second">
					<el-select v-model="consumTypeValue" @change='handleChange()' placeholder="请选择" class="mb-10">
						<el-option v-for="item in consumType" :key="item.value" :label="item.key" :value="item.value">
						</el-option>
					</el-select>
					<template v-if='dialogTableData.uActyConsumeList'>
						<el-table :header-cell-style="{background:'#eef1f6',color:'#606266'}"
							:data="dialogTableData.uActyConsumeList" style="width: 100%">
							<el-table-column prop="grade" label="消费等级"></el-table-column>
							<el-table-column prop="gradeConsume" label="消费金额"></el-table-column>
							<el-table-column prop="consume" label="消费"></el-table-column>
						</el-table>
					</template>
				</el-tab-pane>
				<el-tab-pane label="支付详情" name="third">
					<template v-if='dialogTableData'>
						<div class="flex">
							<div class="common flex flex-ac">微信支付金额</div>
							<div class="common flex flex-ac"> 鸭币购买金额</div>
						</div>
						<div class="flex">
							<div class="list_data flex flex-ac">{{dialogTableData.goodsConsume?dialogTableData.goodsConsume:0}}</div>
							<div class="list_data flex flex-ac">{{dialogTableData.BlanceConsume?dialogTableData.BlanceConsume:0}}</div>
						</div>
					</template>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
		<el-dialog title="确认修改" :visible.sync="editConsumedDialog">
			<el-form :model="editData" ref="editForm">
				<el-form-item label="累计消费修改" prop="consume"
					:rules="{required: true, message: '金额不能为空', trigger: 'blur'}">
					<el-input v-model="editData.consume" label-width="100px"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="consumeCancel">取 消</el-button>
				<el-button type="primary" @click="consumeFirm" :loading="butLoading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'user',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"CountDown": (resolve) => require(["@/components/CountDown"], resolve),
			// "user-details": (resolve) => require(["@/views/modules/install/userDetails"], resolve),
		},
		computed: {
			...mapState('menu', {
				'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [], //优惠劵类型
			})
		},
		data() {
			return {
				retrieveForm: {
					isBlacklist: '', //是否黑名单 0否1是(默认传0
					isTest: 0,
					userName: '', //用户名称,模糊查询
					phone: '', //用户手机号,模糊查询
					startDate: '', //开始时间
					endDate: '', //结束时间
					userId: '', //用户id
					sortType:0,//0按创建时间 1 按消费等级
				},
				startEndDate: [],
				butLoading: false,
				tabLoading: false,
				columns: [
					// { type: 'selection', align: 'center', width: "55", fixed: 'left', },
					// { type: 'index', label: '序号', width: '60', align: 'center', fixed: 'left', index: this.indexMethod },
					{
						label: 'ID',
						prop: 'id',
						align: 'center',
						width: '80'
					}, {
						columnType: 'custom',
						label: '头像',
						prop: 'img',
						align: 'left',
						width: '80'
					}, {
						label: '用户名',
						prop: 'userName',
						align: 'left',
						'show-overflow-tooltip': true
					}, {
						label: '手机号',
						prop: 'phone',
						align: 'left',
						width: '120'
					}, {
						label: 'openId',
						prop: 'openId',
						align: 'left',
						width: '200'
					}, {
						label: '累计消费',
						prop: 'consume',
						align: 'left',
						width: '80',
						'show-overflow-tooltip': true,
					}, {
						label: '余额',
						prop: 'blance',
						align: 'left',
						width: '80',
						'show-overflow-tooltip': true
					}, {
						label: '创建时间',
						prop: 'createDate',
						align: 'left',
						width: '160'
					}, {
						label: '是否拉黑',
						prop: 'isDele',
						align: 'left',
						width: '80',
						formatData: (e) => {
							return e == 0 ? '白名单' : '黑名单'
						}
					}, {
						label: '类型',
						prop: 'isTest',
						align: 'left',
						width: '50',
						formatData: (e) => {
							return e == 0 ? '正式' : '测试'
						}
					}, {
						columnType: 'button',
						label: '操作',
						align: 'right',
						fixed: 'right',
						width: '420',
						buttons: [{
							type: 'text',
							text: '回收',
							fn: this.handleReclaim,
							hidden: (e) => {
								return e.consume <= 300
							}
						}, {
							type: 'text',
							text: '提示卡',
							fn: this.handlePopup,
						}, {
							type: 'text',
							text: '透视卡',
							fn: this.handlePopup,
						}, {
							type: 'text',
							text: '包邮卡',
							fn: this.handlePopup,
						}, {
							type: 'text',
							text: '优惠金',
							fn: this.handlePopup,
						}, {
							type: 'text',
							text: '虚拟币',
							fn: this.handlePopup,
							hidden: (e) => {
								return this.isButFlag1
							}
						}, {
							type: 'text',
							text: '卡片',
							fn: this.handlePopup,
							hidden: (e) => {
								return this.isButFlag2
							}
						}, {
							type: 'text',
							text: '测试',
							fn: this.handleTest,
							hidden: (e) => {
								return (e.isTest == 0 && this.isButFlag3)
							}
						}, {
							type: 'text',
							text: '正式',
							fn: this.handleTest,
							hidden: (e) => {
								return (e.isTest == 1 && this.isButFlag3)
							}
						}, {
							type: 'text',
							text: '黑名单',
							fn: this.handleBlack,
							hidden: (e) => {
								return e.isDele == 0
							}
						}, {
							type: 'text',
							text: '白名单',
							fn: this.handleBlack,
							hidden: (e) => {
								return e.isDele == 1
							}
						}, {
							type: 'text',
							text: '消费',
							fn: this.handleConsume,
							hidden: (e) => {
								return this.isButFlag4
							}
						}, ],
					},
				],
				tableData: [],
				currentPage: 4,
				cardList: [], //卡片列表
				//弹框
				dialogFormVisible: false,
				dialogTitle: '提示卡',
				ruleForm: {
					couponsName: '', //优惠券名称
					doorSill: 0, //使用门槛
					couponsType: 0, //优惠劵类型
					money: '', //面额
					day: '', //有效天数
					num: 1, //赠送数量
					userId: '', //用户Id
					cardId: '', //卡片id
				},
				consumeDialog: false, //消费弹框
				dialogTableData: {
					uActyConsumeList: [], //活动
					goodConsumeList: [], //一番赏
					goodsConsume: '', //微信消费
					BlanceConsume: '', //欧币支付
				}, //等级消费表格
				activeName: 'first',
				consumId: '',
				editData: '', //编辑的数据
				editConsumedDialog: false, //是否显示累计消费弹窗
				consumTypeValue: '1',
				consumType: [{
					key: '大冒险',
					value: '1'
				}, {
					key: '扭蛋',
					value: '0'
				}],
				//按钮
				isButFlag1: true,
				isButFlag2: true,
				isButFlag3: true,
				isButFlag4: true,
			}
		},
		created() {},
		mounted() {
			let pageButtons = this.$route.meta.buttons
			console.log('按钮', pageButtons)
			if (pageButtons) {
				if (pageButtons.findIndex(item => item.name == '虚拟币') == -1) this.isButFlag1 = false
				if (pageButtons.findIndex(item => item.name == '卡片') == -1) this.isButFlag2 = false
				if (pageButtons.findIndex(item => item.name == '测试') == -1) this.isButFlag3 = false
				if (pageButtons.findIndex(item => item.name == '消费') == -1) this.isButFlag4 = false
			}
			this.getTableData()
			this.findCardList()
		},
		methods: {
			//消费弹框
			handleConsume(row) {
				this.consumeDialog = true
				this.consumId = row.id
				this.handleChange()
			},
			//选择活动
			handleChange() {
				this.$http.get('/appletUser/findUserConsumeGrade', {
					params: {
						id: this.consumId,
						actyType: this.consumTypeValue
					}
				}).then(({
					data: result
				}) => {
					this.dialogTableData.uActyConsumeList = JSON.parse(JSON.stringify(result.data.uActyConsumeList))
					this.dialogTableData.goodConsumeList = JSON.parse(JSON.stringify(result.data.goodConsumeList))
					this.dialogTableData.goodsConsume = JSON.parse(JSON.stringify(result.data.goodsConsume))
					this.dialogTableData.BlanceConsume = JSON.parse(JSON.stringify(result.data.BlanceConsume))
				})
			},
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.getTableData()
			},
			//时间变化
			handPickerChange(value) {
				if (value && value.length != 0) {
					this.retrieveForm.startDate = value[0]
					this.retrieveForm.endDate = value[1]
				} else {
					this.retrieveForm.startDate = ''
					this.retrieveForm.endDate = ''
				}
			},
			//导出
			exportExcel() {},
			//获取表格数据
			getTableData() {
				this.tabLoading = true
				this.$http.get('/appletUser/findAppletUserList', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						...this.retrieveForm
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
				})
			},
			//获取卡片列表
			findCardList() {
				this.$http.get('/card/findCardList', {
					params: {
						currentPage: 1,
						pageSize: 20,
					}
				}).then(({
					data: result
				}) => {
					this.cardList = result.data.list
				})
			},
			//跳转详情
			headerRowClick(row) {
				console.log('详情', row)
			},
			//累计消费修改弹窗
			handleClick(row) {
				this.editData = row
				this.editConsumedDialog = true
			},
			//取消弹窗
			consumeCancel() {
				this.handleChange()
				this.editConsumedDialog = false
			},
			//编辑累计消费
			editGrade() {
				let editData = this.editData
				this.$http.post('/appletUser/editGradeConsumeByUserId', {
					consume: this.editData.consume,
					gradeConsumeId: editData.id,
					userId: editData.appletUserId
				}).then(res => {
					this.$message({
						message: '修改成功',
						type: 'success'
					})
					this.butLoading = false
					this.editConsumedDialog = false
					this.handleChange()
				})
			},
			//回收弹框
			handleReclaim(row) {
				this.$confirm('确认回收吗', '回收提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post(`/appletUser/saveRecByUserId`, {
						id: row.id,
					}).then(res => {
						this.$message({
							message: '回收成功',
							type: 'success'
						})
						this.getTableData()
					})
				}).catch(_ => {})
			},
			// 打开弹框
			handlePopup(row, index, inx, but) {
				console.log(row, index, inx, but)
				this.dialogTitle = but.text
				if (but.text == '提示卡') {
					this.ruleForm = {
						day: '', //有效天数
						num: 1, //赠送数量
						userId: row.id
					}
				} else if (but.text == '透视卡') {
					this.ruleForm = {
						day: '', //有效天数
						num: 1, //赠送数量
					}
				} else if (but.text == '包邮卡') {
					this.ruleForm = {
						day: '', //有效天数
						num: 1, //赠送数量
					}
				} else if (but.text == '优惠金') {
					this.ruleForm = {
						couponsName: '', //优惠券名称
						doorSill: 0, //使用门槛
						couponsType: 0, //类型
						money: '', //面额
						day: '', //有效天数
						num: 1, //赠送数量
					}
				} else if (but.text == '虚拟币') {
					this.ruleForm = {
						money: '', //面额
					}
				} else if (but.text == '卡片') {
					this.ruleForm = {
						cardId: '', //卡片id
					}
				}
				this.ruleForm.userId = row.id
				this.dialogFormVisible = true
			},
			//测试
			handleTest(row, index, inx, but) {
				this.$confirm('您确认设置该用户为' + but.text + '人员？').then(_ => {
					this.$http.post(`/appletUser/editTest`, {
						type: row.isTest ? 0 : 1, //0否 1是
						userId: row.id,
					}).then(res => {
						this.$message({
							message: '设置成功',
							type: 'success'
						})
						this.getTableData()
					})
				}).catch(_ => {})
			},
			//拉黑
			handleBlack(row) {
				this.$confirm(row.isDele ? '您确认把该用户移除黑名单？' : '您确认把该用户加入黑名单？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(_ => {
					this.$http.post(`/appletUser/editAppletUserBlacklist`, {
						isBlacklist: row.isDele ? 0 : 1,
						userId: row.id,
					}).then(res => {
						this.$message({
							message: row.isDele ? '解除黑名单成功' : '加入黑名单成功',
							type: 'success'
						})
						this.getTableData()
					})
				}).catch(_ => {})
			},
			//确认弹框
			affirmPopup() {
				this.$refs['ruleForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						let apiUrl = ''
						if (this.dialogTitle == '提示卡') {
							apiUrl = '/userTip/addUserTip'
						} else if (this.dialogTitle == '透视卡') {
							apiUrl = '/lucency/webSaveLucency'
						} else if (this.dialogTitle == '包邮卡') {
							apiUrl = '/postageCard/addPostageCrad'
						} else if (this.dialogTitle == '优惠金') {
							apiUrl = '/coupons/saveCoupons'
						} else if (this.dialogTitle == '虚拟币') {
							apiUrl = '/appletUser/balanceRecharge'
						} else if (this.dialogTitle == '卡片') {
							apiUrl = '/userCard/addUserCard'
						}
						this.$http.post(apiUrl, this.ruleForm).then(({
							data: result
						}) => {
							this.$message({
								message: '新增成功',
								type: 'success'
							})
							this.butLoading = false
							this.dialogFormVisible = false
							if (this.dialogTitle == '虚拟币') {
								this.getTableData()
							}
						}).catch(err => {
							this.butLoading = false
						})
					}
				})
			},
			//累计消费确认弹窗
			consumeFirm() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						this.butLoading = true
						this.editGrade()
					}
				})
			}
		},
	}
</script>
<style lang="less" scoped>
	.common {
		width: 50%;
		height: 44px;
		background-color: #EBEEF5;
		color:rgb(96, 98, 102);
		font-weight: 600;
		padding-left: 10px;
	}
	.list_data {
		width: 50%;
		height: 55px;
		padding-left: 10px;
	}
</style>
